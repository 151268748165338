.header-content {
  background: var(--color-primary);
}
.header-content .slide-titel {
  font-family: var(--font-normal);
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: 0;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide {
  background: var(--color-primary);
  height: 100%;
  display: block;
  min-height: 33rem;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--color-primary);
}

.container-slider {
  width: 100%;
}

.content-slider-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  background: var(--color-primary);
  overflow: hidden;
}

.slider-icon {
  width: 100%;
  height: 100%;
}

img.slider-icon-img {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}

.slider-content {
  width: 100%;
  background: var(--color-primary);
  height: 100%;
  text-align: center;
  margin: auto;
  padding: 1rem;
  color: white;
}
h1.slide-titel,
.slide-titel {
  font-size: 3rem;
    line-height: 56px;
  color: white;
  font-family: var(--font-header);
}

p.slide-titel {
  color: white;
  font-size: 1.2rem;
}

.slider-content a.btn.btn-secondary {
  display: inline-block;
  margin-top: 1rem;
}
.slider-triangle-front,
.slider-triangle-back {
  display: none;
}

@media screen and (min-width: 35.5rem) {
  .swiper-slide {
    min-height: 36rem;
  }
}
@media screen and (min-width: 48rem) {
  .swiper-slide {
    min-height: 42rem;
  }
}
@media screen and (min-width: 64rem) {
  .swiper-slide {
    min-height: 47rem;
  }
}
@media screen and (min-width: 80rem) {
  .swiper-slide {
    min-height: 0;
  }
  .slider-icon {
    width: 65%;
  }
  .slider-content {
    width: 35%;
    padding-right: 12rem;
    padding-left: 0rem;
    text-align: right;
  }
  .slider-triangle-back {
    position: absolute;
    display: block;
    top: -500px;
    bottom: 0;
    right: 24%;
    z-index: 0;
    width: 0px;
    height: 0px;
    -webkit-transform:rotate(360deg);
    border-style: solid;
    border-width: 0 0 1800px 800px;
    border-color: transparent transparent var(--color-primary) transparent;
  }

  .slider-triangle-front {
    position: absolute;
    display: block;
    top: -500px;
    bottom: 0;
    right: 25%;
    z-index: 0;
    width: 0px;
    height: 0px;
    -webkit-transform:rotate(360deg);
    border-style: solid;
    border-width: 0 0 1800px 800px;
    border-color: transparent transparent rgba(255,255,255, 0.6) transparent;
  }
}