.leistungen {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.leistungen-slider {
  width: 100%;
}
.swiper-wrapper {
  width: 100%;
}
.leistung {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 25rem;
  width: 100%;
}
.leistung--image {
  width: 100%;
  height: 50%;
  background-size: cover;
}

.leistung-content {
  width: 100%;
  height: 50%;
  padding: 1rem;
  background: #f3f3f3;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.leistung-content h3{
  font-family: var(--font-normal);
  font-weight: bold;
}
@media screen and (min-width: 48rem) {
  .leistung-content,
  .leistung--image {
    width: 50%;
    height: 100%;
  }
  .leistung {
    height: 15rem;
  }
}