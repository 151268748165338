.team-flex {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  flex-wrap: wrap;
}
.team--box {
  width: calc(100% / 1);
  margin-bottom: 1rem;
  margin-right: 0;
}
.team-content {
  padding: 1rem;
  background: #f3f3f3;

  min-height: 7.25rem;
}
@media screen and (min-width: 48rem) {
  .team--box {
    width: calc(90% / 2);
    margin-bottom: 1rem;
    margin-right: 5%;
  }
}
@media screen and (min-width: 64rem) {
  .team--box {
    width: calc(85% / 3);
    margin-bottom: 1rem;
    margin-right: 5%;
  }
}