.layout-round-image > .container {
    border: none !important;
    padding: 0 !important;
}

.layout-round-image:last-of-type {
    margin-bottom: 40px;
}

.layout-round-image:first-of-type {
    margin-top: 40px;
}

.layout-round-image__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.layout-round-image__inner.image-right {
    justify-content: center;
}

.layout-round-image__inner.image-left {
    justify-content: center;
}

.layout-round-image__content {
    width: 500px;
    font-size: 26px;
    line-height: 34px;
}

.layout-round-image__image-container {
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.layout-round-image__image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 90%;
    width: 90%;
    border-radius: 50%;
    position: relative;
}

.layout-round-image__image .circle-border {
    background-image: url("img/circle.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 386px;
    height: 386px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.layout-round-image__image--right {
    margin-left: 20px;
}

.layout-round-image__image--left {
    margin-right: 20px;
}

.layout-round-image__inner.image-right .layout-round-image__content {
    padding-right: 20px;
}

.layout-round-image__arrow-right {
    margin-right: -32px;
}

@media screen and (max-width: 948px) {
    .layout-round-image__content {
        width: 400px;
    }
}

@media screen and (max-width: 848px) {
    .layout-round-image__arrow-right,
    .layout-round-image__arrow-left {
        display: none;
    }

    .layout-round-image__content {
        width: 100%;
        text-align: center;
    }
    .layout-round-image__content.text-right {
        text-align: center;
    }

    .layout-round-image__inner.image-right {
        flex-direction: column-reverse;
    }
    .layout-round-image__image--right {
        margin-left: 0;
    }

    .layout-round-image__image--left {
        margin-right: 0;
    }

    .layout-round-image__inner.image-right .layout-round-image__content {
        padding-right: 0;
    }

    .layout-round-image {
        margin-bottom: 32px;
    }
}

@media screen and (max-width: 460px) {

    .layout-round-image__image .circle-border {
        width: 286px;
        height: 286px;
    }
    .layout-round-image__image-container {
        width: 300px;
        height: 300px;
    }
}