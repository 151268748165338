/*
 * Filename: typo.css
 *
 * Primary stylesheet which imports every other file.
 */

body {
    font-family: var(--font-normal);
    font-size: var(--font-size);
    line-height: var(--line-height);
    font-weight: 200;
    color: var(--font-color);
    letter-spacing: -0.02em;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
    margin-top: 0;
    margin-bottom: var(--gutter-horizontal);
}

h1 {
    font-family: var(--font-normal);
    font-size: var(--font-size-heading);
    font-weight: 200;
    line-height: 36px;
}

h2 {
    font-family: var(--font-normal);
    font-size: var(--font-size-heading);
    line-height: calc(var(--line-height) * .8);
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: block;
    text-align: center;
}

h3 {
    font-family: var(--font-normal);
    font-size: var(--font-size-subheading);
    font-weight: 900;
}

h4,
h5,
h6 {
    font-family: var(--font-normal);
}

small {
    font-size: var(--font-size-small);
}

ul {
    padding-left: var(--gutter-horizontal);
}

.some-title {
    font-family: 'Futura LT W01 Bold', sans-serif;
    font-size: 22px;
    line-height: 30px;
    font-weight: 900;
    margin-bottom: 40px;
    color: #707070;
}

.pure-g [class *= "pure-u"] {
    font-family: var(--font-normal);
}

.text-bold,
strong, b {
    /*font-family: 'Futura LT W01 Bold', sans-serif;*/
    font-weight: 900;
}

@media screen and (--viewport-md) {
    h1 {
        font-size: var(--font-size-title);
        line-height: 50px;

    }

    h2 {
        font-size: var(--font-size-heading);
        font-weight: 200;
    }

    h3 {
        font-size: var(--font-size-subheading);
    }
}
