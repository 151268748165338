/* allgemeine Infos und viewport-sx 320px */
.mobile-navigation {
  font-size: var(--font-size);
  overflow-y: scroll;
  overflow-x: scroll;
}

.mobile-navigation a {
  padding: calc(var(--gutter) / 2);
  width: 100%;
  position: relative;
  display: flex;
}

.mobile-navigation .header-logo img {
  margin-right: auto;
  margin-left: auto;
}

.mobile-navigation .first-layer {
  color: var(--color-primary);
}

.mobile-navigation .first-layer:hover {
  color: var(--color-primary);
}

.mobile-navigation .first-layer + ul {
  display: none;
}

.mobile-navigation .first-layer.open + ul {
  display: block;
}

.mobile-navigation .first-layer::before {
  border: none;
}

.mobile-navigation .dropdown-list-item {
  border: none;
  padding: 0;
}

.mobile-navigation .dropdown-list-item a {
  margin-bottom: 0;
}

.mobile-navigation .dropdown {
  background: transparent;
  box-shadow: none;
  margin: 0;top: 0;
  position: relative;
  padding: 0;
  padding-left: calc(var(--gutter) * 2);
  list-style: none;
}

.mobile-navigation .dropdown-link {
  color: white;
}

.main-navigation li:hover > .dropdown-link {
  color: var(--color-primary);
}

.mobile-navigation li:hover .dropdown-link:hover {
  color: var(--color-grey-dark);
}

.mobile-navigation .dropdown-link-layer-2 {
  color: white;
}

.mobile-navigation li:hover .dropdown-link-layer-2 {
  font-size: var(--font-size);
  color: #ffffff;
}

.mobile-navigation li:hover .dropdown-link-layer-2:hover {
  font-size: var(--font-size);
  color: var(--color-grey-dark);
}

.mobile-navigation .dropdown-list-item ul {
  list-style-type: none;
  margin-bottom: 0;
}

.mobile-navigation .dropdown-list-item li {
  padding: 0;
}
