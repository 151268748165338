/*
 * Filename: theme.css
 *
 * Stylesheet for all theme relevant attributes like colors, fonts ...
 */


/*

font-family:'Futura LT W01 Light Condensed';
font-family:'Futura LT W01 Bold';
font-family:'Futura LT W01 Light';
font-family:'Futura LT W01 Book';
font-family:'Futura LT W01 Bold Condensed';

*/

@font-face {
  font-family: 'Conv_HandTest';
  src: url('fonts/HandTest.eot');
  src: local('☺'), url('fonts/HandTest.woff') format('woff'), url('fonts/HandTest.ttf') format('truetype'), url('fonts/HandTest.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?iiikl3');
    src:  url('fonts/icomoon.eot?iiikl3#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?iiikl3') format('truetype'),
    url('fonts/icomoon.woff?iiikl3') format('woff'),
    url('fonts/icomoon.svg?iiikl3#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-right:before {
    content: "\e901";
}
.icon-left:before {
    content: "\e902";
}
.icon-Confirm:before {
    content: "\e900";
}



:root {

  /** Schriftgrößen */
  --font-size: 1rem;
  --font-size-title: 2.625rem;
  --font-size-heading: 1.8rem;
  --font-size-subheading: 1.25rem;
  --font-size-small: .8125rem;

  /** Raster */
  --line-height: 1.5em;

  --gutter: 1rem;
  --gutter-horizontal: 1rem;

  /** Farben */
  --font-color: #312d30;
  --code: #c7254e;
  --error: #c7254e;
  --success: #38ae67;
  --color-grey-light: #ccc;
  --color-grey-dark: #333;
  --color-primary: #fd8323;
  --color-secondary: #000000;
  --color-primary-hover: color(#fd8323 a(80%));
  /** Fonts **/

  --font-header: 'Conv_HandTest';
  --font-normal: 'Futura LT W01 Light';
  --font-light: 'Futura LT W01 Light';
  --font-bold: 'Futura LT W01 Bold';
}


@custom-media --viewport-sm (min-width: 35.5rem);
@custom-media --viewport-md (min-width: 48rem);
@custom-media --viewport-lg (min-width: 64rem);
@custom-media --viewport-xl (min-width: 80rem);
