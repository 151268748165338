.meinungen-wrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.meinungen-inner {
  margin-top: 1rem;
  display: block;
  position: relative;
  background-size: auto;
  background: url(img/testimonials-hintergrund-crossword.png) repeat left top;

}

.meinungen-wrapper h2 {
  margin-top: 0;
}

div#mmeinungen {
  background: transparent;
  margin-left: 0;
}

.swiper-wrapper {
  background: transparent;
}

.swiper-slide.meinungs-slide {
  background: transparent;
}

.meinungen-img-wrapper {
  position: absolute;
  top: 0;
}

.meinungen-flex {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.meinungen-right {
  display: none;

  position: relative;
  width: 100%;
  min-height: 15rem;
}

.meinungen-left {
  width: 100%;
}

.meinungen-img-wrapper {
  top: -20%;
  right: 0;
  text-align: right;
}

.meinungen-img-wrapper img {
  width: 90%;
  max-width: 100%;
  height: auto;
}

.meinungsbox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: .5rem 1rem;
  padding-bottom: 3rem;
  width: 100%;
  height: 100%;
}

.meinungen-img {
  width: 100%;
}

.meinungen-text {
  width: 100%;
  padding-left: 2rem;
}
.meinungen-text::before {
  content: '' ;
  background: url(img/qoutes.png) no-repeat top left;
  background-size: contain;
  height: 1.5rem;
  width:1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}
.meinungen-bild {
  display: flex;
  width: 6rem;
  height: 6rem;
  justify-content: center;
  align-self: center;
  border-radius: 5rem;
  overflow: hidden;
  margin-bottom: var(--gutter);
  margin-left: auto;
  margin-right: auto;
}

.meinungen-bild img {
  height: 100%;
  width: auto;
}

.meinungen-text p {
  margin-bottom: calc(var(--gutter) * 0.5);
}

.meinungen-name {
  font-size: 0.8rem;
  line-height: 1.2;
  margin-bottom: 0;
  text-transform: uppercase;
}

.swiper-button-prev.swiper-button-grau,
.swiper-container-rtl .swiper-button-prev.swiper-button-grau {
  /*
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23e8e8e8'%2F%3E%3C%2Fsvg%3E");
  */
  background-image: url("img/testimonials-arrow-left.svg")
}

.swiper-button-next.swiper-button-grau,
.swiper-container-rtl .swiper-button-next.swiper-button-grau {
 /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23e8e8e8'%2F%3E%3C%2Fsvg%3E");*/
  background-image: url("img/testimonials-arrow-right.svg");
}

.swiper-button-prev, .swiper-button-next {
  top: 6rem;
}


.swiper-button-prev, .swiper-button-next {
  top: 90% !important;
}

.swiper-wrapper {
  padding-bottom: 2rem;
}

.swiper-button-next.swiper-button-grau {
  right: 35%;
}

.swiper-button-prev.swiper-button-grau {
  left: 35%;
}

@media screen and (min-width: 25rem) {

}

@media screen and (min-width: 48rem) {
  .meinungen-wrapper {
    margin-bottom: 4rem;
  }
  .meinungen-inner {
    margin-top: 4rem;
  }
  .meinungen-right {
    width: 60%;
    display: block;
  }

  .meinungen-left {
    width: 40%;
  }
  .meinungsbox {
    width: 85%;
  }

  .meinungen-bild {
    margin-bottom: 0;
  }

  .meinungen-img {
    width: 10%;
  }

  .meinungen-text {
    width: 90%;
    padding-left: 2rem;
  }

  .swiper-button-prev, .swiper-button-next {
    top: 60%;
  }
  .swiper-button-next.swiper-button-grau {
    right: 43%;
  }

  .swiper-button-prev.swiper-button-grau {
    left: 43%;
  }
}

@media screen and (min-width: 80rem) {

}