/*
 * Filename: forms.css
 *
 * Default Styles for Forms.
 */

label,
.pure-form label {
  display: block;
}

textarea,
.pure-form textarea {
  display: block;
}

.pure-form fieldset {
  margin: 0;
  border: 0;
  padding: 0;
}

.btn {
  border-style: none;
  border-radius: 0;
  padding: 0.7rem 1rem;
  cursor: pointer;
  transition: background .7s, color .7s;
  outline: none;
}

.btn-primary {
  background: var(--color-primary);
  color: white;

}

.btn-primary:hover {
  background: var(--color-secondary);
}

.btn-secondary {
  background: white;
  color: black;
}

.btn-secondary:hover {
  background: var(--color-secondary);
  color: white;
}

.btn-simple {
  position: relative;
  background: white;
  color: #969593;
  border: solid 1px #ebebeb;

}
.btn-simple:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0px;
  height: 0px;
  -webkit-transform: rotate(360deg);
  border-style: solid;
  border-width: 0 0 15px 15px;
  border-color: transparent transparent #ebebeb transparent;
  transition: border-color 0.5s ease-in;
}
.btn-simple:hover {
  background: var(--color-primary);
  color: white;
  border: solid 1px var(--color-primary);
}

