.quotation {
    margin-top: 96px;
}

.quotation > .container {
    border: none !important;
    padding: 0 !important;
}

.quotation__quote {
    font-size: 34px;
    line-height: 42px;
    color: #707070;
    max-width: 73%;
    padding-left: 46px;
    position: relative;
}

.quotation__quote::before {
    content: '';
    background-image: url("img/anfuerungszeichen.png");
    position: absolute;
    top: 16px;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 34px 34px;
    background-repeat: no-repeat;
}

@media screen and (max-width: 1023px) {
    .quotation {
        margin-top: 40px;
    }

    .quotation__quote {
        max-width: 100%;
    }
}


@media screen and (max-width: 567px) {
    .quotation {
        margin-top: 10px;
    }

    .quotation__quote {

        font-size: 24px;
        line-height: 32px;
    }
}