.jobad-detail {
    margin: 50px 0;
}

.jobad-detail > .container {
    padding: 0 !important;
    border: none !important;
}

.jobad-detail__inner {
    display: flex;
    flex-wrap: wrap;
}

.jobad-detail__sidebar {
    width: calc(30% - 20px);
    margin-left: 20px;
}

.jobad-detail__content-block {
    width: 70%;
}

.jobad-detail__image {
    margin-bottom: 32px;
}

.jobad-detail__image img {
    width: 100%;
    height: auto;
}

.jobad-detail__contact {
    margin-top: 20px;
    background: #E1E1E1;
    padding: 32px 20px;
}

.jobad-detail__content ul {
    list-style: none;
    padding-left: 0;
}

.jobad-detail__content ul > li::before {
    font-family: 'icomoon' !important;
    content: "\e900";
    color: var(--color-primary);
    margin-right: 8px;
}

/* --------------------------- SIDEBAR --------------------------- */

.jobad-detail__other {
    background-color: #E1E1E1;
    padding: 32px 20px;
}

.jobad-detail__sidebar .some-title {
    margin-bottom: 20px
}

.jobad-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.jobad-list > li {
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #fff;
}

.jobad-list > li > a {
    color: #4D4A51;
}

.jobad-list > li.active > a {
    font-weight: 900;
    color: var(--color-primary);
}

.jobad-list > li > a:hover {
    color: var(--color-primary);
}

.jobad-list > li:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

/* --------------------------- NAV --------------------------- */

.jobad-nav {
    margin: 96px 0;
}

.jobad-nav > .container {
    padding: 0 !important;
    border: none !important;
}

.jobad-nav__contact a {
    text-align: center;
    background: var(--color-primary);
    padding: 16px 20px;
    color: #fff;
    display: inline-block;
}

.jobad-nav__contact a:hover {
    color: var(--font-color);
}

.jobad-nav__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.jobad-nav__contact::after {
    content: '';
    background-image: url(img/rechteck_high.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
    position: absolute;
    top: -10px;
    right: -12px;
    bottom: -10px;
    left: -5px;
}

.jobad-nav__prev > span,
.jobad-nav__next > span,
.jobad-nav__prev a,
.jobad-nav__next a {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: var(--font-color);
}

.jobad-nav__prev a:hover,
.jobad-nav__next a:hover {
    color: var(--color-primary);
}

.icon-left.rect {
    margin-right: 10px;
}

.icon-right.rect {
    margin-left: 10px;
}

@media screen and (max-width: 889px) {
    .jobad-detail__sidebar {
        width: 100%;
        margin-left: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
    }

    .jobad-detail__content-block {
        width: 100%;
    }

    .jobad-detail__contact,
    .jobad-detail__other {
        width: calc(50% - 20px * 1 / 2);
    }

    .jobad-detail__contact {
        margin-top: 0;
    }
}

@media screen and (max-width: 659px) {
    .jobad-nav__inner {
        justify-content: center;
    }
    .jobad-nav__prev,
    .jobad-nav__contact,
    .jobad-nav__next {
        width: 100%;

    }
    .jobad-nav__contact {
        margin: 20px 0;
        text-align: center;
    }
    .jobad-nav__next a, .jobad-nav__next > span {
        justify-content: flex-end;
    }
}

@media screen and (max-width: 635px) {
    .jobad-detail__contact,
    .jobad-detail__other {
        width: 100%;
    }

    .jobad-detail__contact {
        margin-top: 20px;
    }
}



