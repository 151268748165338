.jobad-listing {
    margin-top: 96px;
}

.jobad-listing > .container {
    padding: 0 !important;
    border: none !important;
}

.jobad-listing__items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
}

.jobad-item {
    width: calc(33.33% - 20px * 2 / 3);
    margin-bottom: 20px;
}

.jobad-item:nth-child(n):not(:nth-child(3n)) {
    margin-right: 20px;
}

.jobad-item__image img {
    width: 100%;
    height: auto;
}

.jobad-item__image-container {
    width: 320px;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.jobad-item__image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 90%;
    width: 90%;
    border-radius: 50%;
    position: relative;
    transition: box-shadow .4s ease-in-out;
    margin-bottom: 32px;
}

.jobad-item__image::after {
    /*content: '';*/
    /*background-image: url("img/circle_active.png");*/
    /*filter: saturate(0) brightness(1.5);*/
    /*background-size: contain;*/
    /*background-repeat: no-repeat;*/
    /*background-position: center center;*/
    /*width: 306px;*/
    /*height: 306px;*/
    /*position: absolute;*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
    /*transition: filter .4s ease-in-out;*/
}

.jobad-item__image .circle-border {
    /*content: '';*/
    background-image: url("img/circle_active.png");
    filter: saturate(0) brightness(1.5);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 306px;
    height: 306px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: filter .4s ease-in-out;
}

.jobad-item:hover .jobad-item__image .circle-border {
    filter: saturate(1) brightness(1);
    transition: filter .4s ease-in-out;
}

.jobad-item:hover .jobad-item__image {
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.4);
    transition: box-shadow .4s ease-in-out;
}

.jobad-item__title {
    transition: color .4s ease-in-out;
}

.jobad-item:hover .jobad-item__title {
    transition: color .4s ease-in-out;
    color: var(--color-primary);
}

@media screen and (max-width: 1023px) {
    .jobad-item {
        width: calc(50% - 20px * 1 / 2);
    }

    .jobad-item:nth-child(n):not(:nth-child(3n)) {
        margin-right: 0;

    }

    .jobad-item:nth-child(n):not(:nth-child(2n)) {
        margin-right: 20px;
    }
}

@media screen and (max-width: 767px) {
    .jobad-item {
        width: 100%;
    }

    .jobad-listing {
        margin-top: 20px;
    }

    .jobad-item:nth-child(n):not(:nth-child(2n)) {
        margin-right: 0;
    }

    .jobad-item__image-container {
        width: 220px;
        height: 220px;
    }

    .jobad-item__image .circle-border {
        width: 216px;
        height: 216px;
    }
}