.meinungen-footer {
  background: var(--color-primary);
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.meinungen-footer h2 {
  color: white;
}
.meinungen-footer-flex {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.meinungen-footer-box {
  width: calc(100%/1);
  padding: 1rem;
}

.meinungen-footer-box-text {
  background: white;
  padding: 1rem  3rem  1rem;
  color: black;
  margin-bottom: 2rem;
}
.meinungen-footer-box-text::before {
  content: '' ;
  background: url(img/qoutes-orange.png) no-repeat top left;
  background-size: contain;
  height: 1.5rem;
  width:1.5rem;
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  display: block;
}
.meinungen-footer-box-text::after {
  content: '' ;
  position: absolute;
  bottom: -15px;
  right: 1rem;
  display: block;
  width: 0px;
  height: 0px;
  -webkit-transform:rotate(360deg);
  border-style: solid;
  border-width: 15px 20px 0 20px;
  border-color: #ffffff transparent transparent transparent;
}
.meinungen-footer-name {
  text-align: right;
}
@media screen and (min-width: 48rem) {
  .meinungen-footer-box {
    width: calc(100%/2);
    padding: 1rem;
  }
}
@media screen and (min-width: 64rem) {
  .meinungen-footer-box {
    width: calc(100%/3);
    padding: 1rem;
  }
}