.steps {
    margin: 96px 0;
}

.steps > .container {
    border: none !important;
    padding: 0 !important;

}

.steps img {
    width: 100%;
    height: auto;
    text-align: center;
}

.steps__inner {
    display: flex;
    flex-wrap: wrap;
}

.step {
    width: 33%;
    filter: saturate(0) brightness(1.3);
}

.step.active {
    filter: saturate(1) brightness(1);
}

.step__title {
    font-family: 'Futura LT W01 Bold', sans-serif;
    font-size: 26px;
    line-height: 34px;
    font-weight: 900;
    text-transform: uppercase;
    color: #fd8323;
}

@media screen and (max-width: 699px) {
    .step {
        width: 100%;
        max-width: 304px;
    }
    .steps__inner {
        flex-direction: column;
        align-items: center;
    }
}