.parallax {
  background-size: cover;
  background: no-repeat fixed center top;
  display: block;
  position: relative;
}
.parallax::before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.parallax--content {
  z-index: 5;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: block;
  position: relative;
  color: white;
  text-align: center;
  font-size: 1.2rem;
}
.parallax--content h2{
  margin-bottom: 0;
  font-family: var(--font-bold);
  display: block;
  position: relative;
}
.parallax--content h3{
  margin-top: 1rem;
  font-weight: 200;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  display: block;
  position: relative;
}
.parallax--content-trenner {
  content: '';
  width: 5rem;
  height: 5px;
  background: var(--color-primary);
  display: inline-block;
  position: relative;
  margin: 1rem auto 2rem;
}