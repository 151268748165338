/*
 * Filename: helpers.css
 *
 * Helper classes
 */

.visible,
.visible-xs {
  display: initial !important;
}

.visible-sm,
.visible-md,
.visible-lg,
.visible-xl {
  display: none !important;
}

.hidden-xs {
  display: none !important;
}

.color-primary {
  color: var(--color-primary);
}

.color-secondary {
  color: var(--color-secondary);
}

.color-grey-light {
  color: var(--color-grey-light);
}

.color-grey-dark {
  color: var(--color-grey-dark);
}

.color-error {
  color: var(--error);
}

.color-success {
  color: var(--success);
}

.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}

@media screen and (--viewport-sm) {
  .visible-xs,
  .visible-md,
  .visible-lg,
  .visible-xl {
    display: none !important;
  }

  .visible-sm {
    display: initial !important;
  }

  .hidden-xs {
    display: initial !important;
  }

  .hidden-sm {
    display: none !important;
  }
}

.rect {
    background: var(--color-primary);
    color: #fff;
    height: 40px;
    width: 40px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

span[disabled] {
    cursor: not-allowed;
    filter: saturate(0) brightness(1.5);
}

@media screen and (--viewport-md) {
  .visible-xs,
  .visible-sm,
  .visible-lg,
  .visible-xl {
    display: none !important;
  }

  .visible-md {
    display: initial !important;
  }

  .hidden-sm {
    display: initial !important;
  }

  .hidden-md {
    display: none !important;
  }
}

@media screen and (--viewport-lg) {
  .visible-xs,
  .visible-sm,
  .visible-md,
  .visible-xl {
    display: none !important;
  }

  .visible-lg {
    display: initial !important;
  }

  .hidden-md {
    display: initial !important;
  }

  .hidden-lg {
    display: none !important;
  }
}

@media screen and (--viewport-xl) {
  .visible-xs,
  .visible-sm,
  .visible-md,
  .visible-lg {
    display: none !important;
  }

  .visible-xl {
    display: initial !important;
  }

  .hidden-lg {
    display: initial !important;
  }

  .hidden-xl {
    display: none !important;
  }
}
