.historie--box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
}
.historie--year {
  text-align: center;
  padding-bottom: 1rem;
  font-size: 1.8rem;

  width: 100%;


}

.historie--content {
  width: 100%;

  padding-bottom: 2rem;
}
.historie--content-inner {
  border: solid 1px #dddcdd;
  position: relative;
  display: block;
  padding: 2rem;
  background-size: auto;
  background: url(img/testimonials-hintergrund-crossword.png) repeat left top;
}
.historie--content-inner h3 {
 color: var(--color-primary);
}

@media screen and (min-width: 48rem) {
  .historie--year {
    text-align: right;
    width: 25%;
    border-right: solid 2px #e5e5e5;
    padding-right: 4rem;
    padding-bottom: 0;
    margin-right: 5%;
  }
  .historie--content {
    padding-left: 2rem;
    width: 70%;
  }
  .historie-triangle-back {
    position: absolute;
    top: 0;
    left: -30px;
    width: 0px;
    height: 0px;
    /* background-size: auto;
     background: url(img/testimonials-hintergrund-crossword.png) repeat left top;*/
    -webkit-transform:rotate(360deg);
    border-style: solid;
    border-width: 0 30px 30px 0;
    border-color: transparent #fbfbfb transparent  transparent;
    z-index: 5;
  }
  .historie-triangle-border {
    position: absolute;
    top: -1px;
    left: -32px;
    width: 0px;
    height: 0px;
    -webkit-transform:rotate(360deg);
    border-style: solid;
    border-width: 0 31px 31px 0;
    border-color: white #dddcdd white  white;
    z-index: 4;
  }
  .historie--round {
    position: absolute;
    top: -17.5px;
    right: -18.5px;
    background: white;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    border: solid 4px var(--color-primary);
    box-shadow:0px 0px 0px 4px rgba(255,255,255, 1);
    -moz-box-shadow:0px 0px 0px 4px rgba(255,255,255, 1);
    -webkit-box-shadow:0px 0px 0px 4px rgba(255,255,255, 1);
  }
}