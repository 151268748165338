header {
  background-size: auto;
  background: url(img/black-twill.png) repeat left top;
  position: fixed;
  color: white;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0 1px 14px 0 rgba(0, 0, 0, .55);
  -moz-box-shadow: 0 1px 14px 0 rgba(0, 0, 0, .55);
  -webkit-box-shadow: 0 1px 14px 0 rgba(0, 0, 0, .55);
}

.header-logo-mobil {
  margin-left: 1rem;
  padding: 0.2rem;
}

.header-logo-mobil a {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.header-logo-mobil img {
  height: 3rem;
}

.hamburger-button svg {
  width: 2rem;
  height: 2rem;
  display: block;
  margin: auto auto;
}

.hamburger-button {
  height: 2.5rem;
  width: 2.5rem;
  outline: none;
  border: medium none;
  color: #f5f5f5;
  font-weight: 700;
  padding: 0;
  cursor: pointer;
  margin-left: auto;
  margin-bottom: auto;
  margin-top: auto;
  margin-right: 1rem;
}

.hamburger-button {
  background-color: var(--color-primary);
}

.hamburger-button:hover {
  background-color: clac(var(--color-primary) a(80));
}

.header-right {
  display: none;
  width: 100%;
  text-align: right;
  padding: 1rem;
}

.info-group {
  color: white;
  font-size: 1.1rem;
}

.info-group img {
  height: 1.1rem;
  width: 1.1rem;
}
.header-logo {
  display: none;
}
.header-logo a {
  margin-left: 1rem;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.header-logo img {
  max-height: 6rem;
  height: 6rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin-left: 1rem;
}

ul.main-navigation {
  list-style: none;
  padding-left: 0;
  margin-top: var(--gutter);
}

ul.main-navigation > li {
  line-height: 0.5rem;
  height: auto;
}

img {
}

a.info-group img {
  margin-right: 0.5rem;
}

a.info-group:last-of-type {
  margin-right: 0.5rem;
}

a.info-group {
  margin-right: 2.5rem;
  display: flex;
  justify-content: flex-end;
  width: auto;
  align-content: center;
  align-items: center;
}
a:hover.info-group {
  color: var(--color-primary);
}
.header-info {
  display: none;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

ul.main-navigation {
  margin-top: 0.5rem;
}

.main-navigation li:not(.header-logo) > a {
  font-family: var(--font-light);
  font-size: 1.3rem;
  color: white;
}

.main-navigation li > a:hover,
.main-navigation li > a:focus,
.main-navigation li > a:active,
.main-navigation li.active > a {
  color: var(--color-primary-hover);
}

.menu-img {
  background: #383437;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-right: 0.5rem;
  margin-left: 0;
  transition: background 0.5s, border 0.5s;
}

.main-navigation li > a:hover .menu-img,
.main-navigation li.active > a .menu-img {
  background: var(--color-primary-hover);

}

.menu-icon {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.menu-icon img {
  height: 0.8rem;
  width: 0.8rem;
}
.main-navigation li > a {
  display: block;
  position: relative;
  padding: 1rem 1rem 0.5rem 1rem;
}

.dropdown {
  left: 0;
  top: 55px;
  background-size: auto;
  background: url(img/black-twill.png) repeat left top;
  box-shadow: 0px 3px 5px 0px rgba(0,0,0, 0.75);
  -moz-box-shadow:0px 3px 5px 0px rgba(0,0,0, 0.75);
  -webkit-box-shadow:0px 3px 5px 0px rgba(0,0,0, 0.75);
}

a.dropdown-link.dropdown-field {
  line-height: 1rem;
  display: block;
  text-align: initial;
}
.dropdown-list-item {
  padding: 0 var(--gutter);
}

.dropdown > li:last-of-type {
  border-right: none;
}

.dropdown > li:last-of-type > a {
    padding-bottom: 1rem;
}

.dropdown > li:first-of-type {
  padding-left: 0;
}

.dropdown-list-item ul {
  font-size: var(--font-size-small);
  list-style-type: disc;
}

.dropdown-link {
  display: block;
  color: var(--font-color);
  text-decoration: none;
  padding: 0.5rem;
}

.submenu-2 {
  margin-bottom: var(--gutter-horizontal);
}

.dropdown-link-layer-2 {
  color: var(--font-color);
  text-decoration: none;
}

.dropdown-link:hover,
.dropdown-link-layer-2:hover {
  color: var(--color-primary);
}

.main-navigation .open {
  /*background-color: var(--color-secondary);*/
  color: white;
}

.main-navigation .open + .dropdown {
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
}

.logo--container {
  display: none;
}

.header-img-left {
  display: none;
}

@media screen and (min-width: 48rem) {
  .header-logo {
    display: block;
  }
  .header-info {
    display: flex;
  }
  .header-right {
    display: block;
  }
  .main-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }

  ul.main-navigation {

    width: 100%;
    margin-bottom: 0;
    margin-top: 0;
  }

  ul.main-navigation--left {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-end;
  }

  ul.main-navigation--right {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;

  }

  .header-img-left {
    display: block;
  }

  .header-img-left img {
    width: auto;
    height: 100%;
  }

  ul.main-navigation li {
    display: inline-block;
  }

  ul.main-navigation > li {
    display: inline-block;
    position: relative;
  }

  ul.main-navigation > li:not(.header-logo) > a {
    display: flex;
    height: 100%;
    text-align: left;
    line-height: 1rem;
  }

  ul.main-navigation > li:not(.header-logo) > a:hover {
    color: var(--color-primary);
  }

  /* 2. Ebene */
  .dropdown {
    display: none;
    position: absolute;
    background-color: white;
    margin-bottom: 0;
    width: 19rem;
    padding: 0;
    list-style-type: none;
    z-index: 100;
  }

  ul.main-navigation li .dropdown-link,
  ul.main-navigation li .dropdown-link-layer-2 {
    color: white;
  }
  ul.main-navigation a:hover .dropdown-link,
  ul.main-navigation a:hover .dropdown-link-layer-2 {
    color: var(--color-primary);
  }

  ul.dropdown li {
    display: block;
    width: 100%;
    padding: 0;
    margin-left: 0;
    font-size: var(--font-size);
  }

  ul.dropdown li a:hover {
    color: var(--color-primary) !important;
  }

  .main-navigation .open {
    background-color: transparent;
    color: white;
  }
}

@media screen and (min-width: 55rem) {
  .header-img-left {
    display: none !important;
  }

  ul.main-navigation--right {
    justify-content: flex-end;
    align-items: flex-end;
  }

  ul.main-navigation--left {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .logo--container {
    display: block;
    width: 20%;
    height: 0;
  }

  .head--logo {
    width: 16%;
    margin: 0 auto;
  }

  img.img--logo {
    width: 110px;
    left: 50%;
    margin-left: -55px;
    top: -18px;
    z-index: 601;
  }

  .head--kreis {
    width: 170px;
    min-width: 170px;
    height: 160%;
    position: absolute;
    display: inline-block;
    background-color: #fff;
    border-radius: 0 0 100px 100px;
    -moz-border-radius: 0 0 100px 100px;
    -webkit-border-radius: 0 0 200% 200%;
    left: 50%;
    margin-left: -85px;
    top: -60px;
    z-index: 80;
    box-shadow: 0 1px 14px 0 rgba(0, 0, 0, .55);
    -moz-box-shadow: 0 1px 14px 0 rgba(0, 0, 0, .55);
    -webkit-box-shadow: 0 1px 14px 0 rgba(0, 0, 0, .55);
  }

  .head--white {
    width: 210px;
    min-width: 210px;
    height: 101%;
    position: absolute;
    display: inline-block;
    background-color: #fff;
    -moz-border-radius: 0 0 100px 100px;
    /* -webkit-border-radius: 0 0 200% 200%; */
    left: 0;
    margin-left: -90px;
    top: -60px;
    z-index: 80;
    /* box-shadow: 0 1px 14px 0 rgba(0, 0, 0, .55); */
    -moz-box-shadow: 0 1px 14px 0 rgba(0, 0, 0, .55);
    /* -webkit-box-shadow: 0 1px 14px 0 rgba(0, 0, 0, .55); */
  }
}

@media screen and (min-width: 64rem) {
}

@media screen and (min-width: 80rem) {
  img.img--logo {
    width: 110px;
    margin-left: -55px;
    top: -18px;
  }
}