.main-footer {
  background-size: auto;
  background: url(img/black-twill.png) repeat left top;
  color: white;
  padding-top: 1rem;
}

.main-footer > .container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.main-footer .main-footer-right,
.main-footer .main-footer-left {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: block;
  position: relative;
}

.main-footer .main-footer-left {
  width: 100%;
}

.main-footer .main-footer-right {
  width: 100%;
}

.main-footer h3 {
  padding-bottom: 1rem;
  border-bottom: solid 1px var(--color-primary);
}

.main-footer ul {
  margin: 0;
  padding: 0;
  list-style: none;

}

.main-footer a {
  color: white;
}

.main-footer a:hover {
  color: var(--color-primary);
}

.sub-footer {
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
}

.sub-footer ul {
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  justify-content: center;
}

.sub-footer ul li {
  padding-right: 1rem;
}

@media screen and (min-width: 48rem) {
  .main-footer {
    padding-top: 0;
  }
  .main-footer .main-footer-left {
    width: 39%;
  }

  .main-footer .main-footer-right {
    width: 59%;
  }
  .main-footer ul {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
  }
}

@media screen and (min-width: 80rem) {
}