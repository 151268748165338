.layout-image-and-title {
    margin: 96px 0 40px 0;
}

.layout-image-and-title > .container {
    border: none !important;
    padding: 0 !important;
}

.layout-image-and-title__title {
    /*font-family: 'Futura LT W01 Bold', sans-serif;*/
    /*font-size: 26px;*/
    /*line-height: 32px;*/
    /*font-weight: 900;*/
    /*margin-bottom: 40px;*/
    /*color: #707070;*/
}

.layout-image-and-title__image {
    position: relative;
}

.layout-image-and-title__image::before {
    content: '';
    background-image: url(img/rechteck_high.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.layout-image-and-title__image img {
    width: 100%;
    height: auto;
}